import {
  SET_THREAD_ID,
  SET_THREAD_MEMBERS,
  SET_THREAD_CHATS,
  SET_THREAD_FILTER,
  SET_THREAD_CHAT_SCREEN,
  SET_THREAD_SUBMISSION_DATA,
  SET_THREAD_ESG_COMPLIANCE_ID,
} from "constants/phase-3/thread/threadConst";

const initial_state = {
  thread_id: null,
  esg_compliance_id: null,
  members: [],
  thread_chats: [],
  thread_filter: null, // {type: "sort", value: ""}
  chat_screen: null, // {screen: "SUBMISSION", chat: {...chat}}
  selected_submission_data: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_THREAD_ID:
      return {
        ...state,
        thread_id: action.payload,
      };

    case SET_THREAD_ESG_COMPLIANCE_ID:
      return {
        ...state,
        esg_compliance_id: action.payload,
      };

    case SET_THREAD_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };

    // update the whole thread_chat
    case SET_THREAD_CHATS:
      return {
        ...state,
        thread_chats: action.payload,
      };

    case SET_THREAD_FILTER:
      return {
        ...state,
        thread_filter: action.payload,
      };

    case SET_THREAD_CHAT_SCREEN:
      return {
        ...state,
        chat_screen: action.payload,
      };

    case SET_THREAD_SUBMISSION_DATA:
      return {
        ...state,
        selected_submission_data: action.payload,
      };

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------

// Thread Chat Sample
/* 
[
  {
    _id: "", thread_id: "", type: "MESSAGE", user_data: {user_id: "", name: "", profile_img: ""}, time: "", message: ""
  },
  {
  _id: "", thread_id: "", type: "MESSAGE", user_data: {user_id: "", name: "", profile_img: ""}, time: "", message: "", file: {}
  },
  {
  _id: "", thread_id: "", type: "submission", user_data: {user_id: "", name: "", profile_img: ""}, time: "", ...
  }
]
*/
