import { SET_PROFILE_DETAILS } from "constants/generalSettings/generalSettings";
import apiBolerplate from "utils/_helpers/apiBolerplate";

export const setProfileDetails = (payload) => {
  return {
    type: SET_PROFILE_DETAILS,
    payload,
  };
};

export function getProfileDetails() {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      method: "get",
      url: `/user/me`,
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        // final actions
        dispatch(setProfileDetails(data?.result));
      },
    });
  };
}

export function updateProfileDetails({ data, profile_img }) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      // method: "post",
      url: `/user/update_user_v2`,
      bodyData: { ...data },
      files: profile_img,
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        // final actions
        if (!!data) {
          dispatch(getProfileDetails());
        }
      },
    });
  };
}

export function updateEntityDetails({ data, profile_img }) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: `/entity/update_entity`,
      bodyData: { ...data },
      files: profile_img,
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        // final actions
        if (!!data) {
          dispatch(getProfileDetails());
        }
      },
    });
  };
}
