export const SET_SELECTED_KPI = "SET_SELECTED_KPI";
export const SET_ORGANIZATIONAL_TAGS = "SET_ORGANIZATIONAL_TAGS";
export const SET_KPI_TAGS = "SET_KPI_TAGS";
export const SET_EXCEL_FILES = "SET_EXCEL_FILES";
export const SET_DATAHUB_DATASET = "SET_DATAHUB_DATASET";
export const SET_DATASET_KPI_YEAR = "SET_DATASET_KPI_YEAR";
export const SET_DATAHUB_DATASET_KPI_DATA = "SET_DATAHUB_DATASET_KPI_DATA";

// Datahub
export const SET_DATAHUB_TAB = "SET_DATAHUB_TAB";
export const SET_EXCEL_FILE_DATA = "SET_EXCEL_FILE_DATA";
