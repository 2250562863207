import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import SnackbarCont from "../../containers/snackbar/snackbarCont";
import LoaderCon from "../../containers/loader/loader_cont";

import Controller from "../../containers/router/controllerCont";
import ErrorBoundary from "./ErrorBoundary";
import { useSelector } from "react-redux";
import DetermineRoutes from "./determineRoutes";

// export const MContext = React.createContext();

const Router = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addNewCompany, setAddNewCompany] = useState(false);
  const [redirectHandled, setRedirectHandled] = useState(false);

  const isLoggedin = useSelector((state) => state.user.isLoggedin);
  const signupProcess = useSelector((state) => state.user.signup_process);
  const userType = useSelector((state) => state.user.user_type);

  const cb = useCallback((isOpen) => {
    setIsOpen(isOpen);
  }, []);

  const autoMateLogout = () => {
    const { setLogOut, set_snack_bar } = props;
    localStorage.removeItem("user_token");
    setLogOut();
    set_snack_bar(true, "Please login again");
    return <Navigate to="/login" />;
  };

  useEffect(() => {
    if (
      props.login.isLoggedin &&
      window.location.pathname === "/add_company" &&
      !addNewCompany
    ) {
      setAddNewCompany(true);
    }
  }, [props.login.isLoggedin, addNewCompany]);

  useEffect(() => {
    if (
      props.snackbar.response_received &&
      props.snackbar.message === "Invalid user"
    ) {
      autoMateLogout();
    }
  }, [props.snackbar]);

  // Helper function to determine routes
  // Helper function to determine routes
  // ({
  //   isLoggedin,
  //   signupProcess,
  //   userType,
  //   isOpen,
  //   cb,
  //   props,
  // })
  const routes = useCallback(
    () => (
      <DetermineRoutes
        isLoggedin={isLoggedin}
        signupProcess={signupProcess}
        userType={userType}
        isOpen={isOpen}
        cb={cb}
        {...props}
      />
    ),
    [isLoggedin] // Memoize when any of these change
  );

  console.log("routes", isLoggedin);

  useEffect(() => {
    if (!redirectHandled) {
      setRedirectHandled(true);
    }
  }, [redirectHandled]);

  return (
    <div style={{ overflow: "hidden" }}>
      <BrowserRouter>
        <SnackbarCont />
        <LoaderCon />
        {!redirectHandled && <Controller />}
        {redirectHandled && (
          <ErrorBoundary>
            <Routes>
              {/* Root Route */}
              {/* <Route path="/" element={getRoutes()} /> */}
              {/* Wildcard Route */}
              <Route path="/*" element={routes()} />
            </Routes>
          </ErrorBoundary>
        )}
      </BrowserRouter>
    </div>
  );
};

export default Router;
