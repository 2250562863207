import React, { useEffect, useState } from "react";
import FrameworkContent from "./frameworkContent";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
// import { makeStyles } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import file from "../../../images/File.png";
import fileVerticle from "../../../images/FileVerticle.png";
import makeStyles from "@mui/styles/makeStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FrameworksDialog(props) {
  const subContentData = [
    {
      id: 0,
      image1: file,
      image2: fileVerticle,
      selected: false,
      templateType: "template_1",
      flipImage: false,
      topic: "Sustainability Report Format",
      content:
        "This is will generate a sustainability reporting format which catalogue all the reporting metrics under various ESG categories on the system",
    },
    {
      id: 1,
      image1: file,
      image2: fileVerticle,
      selected: false,
      templateType: "template_2",
      flipImage: false,
      topic: "Framework Prescribed format",
      content:
        "This will generate the report in format as prescribed by the reporting framework you have chosen.",
    },
  ];

  const [title, setTitle] = useState(false);
  const [titleName, setTitleName] = useState("");

  const [contentId, setContentId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [defaultFilter, setDefaultFilter] = useState("");
  const [template, setTemplate] = useState("");
  const [subContent, setSubContent] = React.useState(subContentData);
  const states = {
    subContentData,
    contentId,
    setContentId,
    selectedIndex,
    setSelectedIndex,
    defaultFilter,
    setDefaultFilter,
    template,
    setTemplate,
    subContent,
    setSubContent,
  };
  // console.log(props);
  const dialogStyles = makeStyles((theme) => ({
    dialogIcon: {
      cursor: "pointer",
      margin: "5px",
      color: "#3374B9",
    },
    dialogContent: {
      borderBottom: "1px solid #BBBBBB",
      padding: "16px 8px",
    },
    dialogContentTypo: {
      fontWeight: "600",
      color: "#6C6C6C",
    },
  }));
  const classes = dialogStyles();
  function handleTitle(content, i) {
    setTitle((preValue) => !preValue);
    setTitleName(typeof content === "object" && content.framework_name);
    if (
      content.template_type !== undefined &&
      content.template_type.template_2
    ) {
      if (props.template === "template_1") {
        setSelectedIndex(0);
        setTemplate("template_1");
      } else if (props.template === "template_2") {
        setSelectedIndex(1);
        setTemplate("template_2");
      }
    } else {
      if (props.template === "template_1" || props.template === "template_2") {
        setSelectedIndex(0);
        setTemplate("template_1");
      }
    }
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} keepMounted>
      <Grid container>
        <Grid
          item
          sm={11}
          md={11}
          lg={11}
          style={{
            display: "flex",
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "80%",
          }}
        >
          {title && (
            <ArrowBackIcon
              onClick={() => setTitle((preValue) => !preValue)}
              style={{
                margin: "10px",
                cursor: "pointer",
                // padding: "20px ",
                // fontWeight: "600",
              }}
            />
          )}
          <Grid container>
            <Typography
              variant="body1"
              style={{
                // marginBottom: "10px",
                // padding: "20px 20px 20px 40px",
                fontWeight: "600",
                margin: "10px",
              }}
            >
              {title ? titleName : "Frameworks"}
            </Typography>
          </Grid>
          <Grid>
            {!title && (
              <CloseIcon
                name="closeFramwork"
                className={classes.dialogIcon}
                onClick={() => {
                  props.setOpen(false);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* {console.log(props.framework)} */}
      {!title &&
        Object.keys(props.framework).map((key, index) => {
          // console.log('key', props.framework[key]);
          return (
            props.framework[key].length > 0 && (
              <React.Fragment key={index}>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    sm={11}
                    md={11}
                    lg={11}
                    className={classes.dialogContent}
                  >
                    <Typography
                      variant="body1"
                      className={classes.dialogContentTypo}
                    >
                      {key === "mandatory_data"
                        ? "National Framework"
                        : key === "recommended_data"
                          ? "Recommended frameworks"
                          : "Others FrameWorks"}
                    </Typography>
                  </Grid>
                </Grid>
                <DialogContent style={{ minWidth: "615px", maxWidth: "650px" }}>
                  <FrameworkContent
                    {...props}
                    states={states}
                    framework={props.framework[key]}
                    setOpen={props.setOpen}
                    open={props.open}
                    onClose={props.onClose}
                    subContent={props.subContent}
                    handleTitle={handleTitle}
                    title={title}
                    template={props.template}
                  />
                </DialogContent>
              </React.Fragment>
            )
          );
        })}

      {title && (
        <DialogContent style={{ minWidth: "602px", maxWidth: "650px" }}>
          <FrameworkContent
            {...props}
            states={states}
            framework={props.framework}
            setOpen={props.setOpen}
            open={props.open}
            onClose={props.onClose}
            subContent={props.subContent}
            handleTitle={handleTitle}
            title={title}
            template={props.template}
          />
        </DialogContent>
      )}
    </Dialog>
  );
}

export default React.memo(FrameworksDialog);
