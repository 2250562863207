var UNIVERSAL = {
  BASEURL:   process.env.React_App_BASEURL, // "http:///0.tcp.in.ngrok.io:16085/api/v3"
  // BASEURL:"https://frontend-dot-imgrows-test.el.r.appspot.com/api",
  //  BASEURL: "https://esg-prl.herokuapp.com/api",
  //BASEURL :  "https://api.imgrows.com/api",
  PDF_MICROSERVICE: process.env.React_App_PDF_MICROSERVICE,
  URL_FOR_SOCKET: process.env.React_App_URL_FOR_SOCKET,
};
// "https://2mvptq0c-8002.inc1.devtunnels.ms/api/v3",
export default UNIVERSAL;
