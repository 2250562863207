import { SET_SUPPLIER_ASSESSMENT_DETAILS } from "constants/phase-3/supplierAssessment/supplierAssessmentConst";

const initial_state = {
  supplierAssessmentDetails: {},
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_SUPPLIER_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        supplierAssessmentDetails: action.payload,
      });

    default:
      return state;
  }
}
